import React from 'react'
import { Box, Button, styled, Typography } from '@mui/material'
import { Link, RouteComponentProps } from '@reach/router'

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '4rem',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
        fontSize: '2rem',
    },
})) as typeof Typography

const SubTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '12rem',
    textAlign: 'center',
    opacity: 0.3,
    position: 'absolute',
    inset: 0,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
        fontSize: '8rem',
    },
})) as typeof Typography

interface Props extends RouteComponentProps {
    Parent?: React.ComponentType
}

const NotFoundView: React.FC<Props> = ({ Parent = (props) => <Box {...props} /> }) => {
    return (
        <Parent>
            <Box
                sx={{
                    height: 'calc(100vh - 64px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                    }}
                >
                    <SubTitle>404</SubTitle>
                    <Title component={'h1'}>Page introuvable</Title>
                </Box>
                <Button
                    component={Link}
                    to="/"
                    variant="contained"
                    color="secondary"
                    sx={{
                        marginTop: 6,
                    }}
                >
                    Retour à l'accueil
                </Button>
            </Box>
        </Parent>
    )
}

export default NotFoundView
